import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Grid, Typography, Paper } from "@mui/material";

// import NavMenuButton from './NavMenuButton';
import CustomButton from './common/customButton';
import CustomModal from './common/customModal';

import '../utils/styles.css';

const Navbar = () => {
    const [navbarOpacity, setNavbarOpacity] = useState(0);
    const [showAlertModal, setShowAlertModal] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const threshold = 10; 

            if (scrollPosition > threshold) {
                setNavbarOpacity(1);
            } else {
                setNavbarOpacity(0);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const styles = {
        mainContainer: {
            padding: '10px'
        },
        imageContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'right',
            justifyContent: 'flex-end',
        },
        logo: {
            width: '30px',
            height: '30px'
        },
        brandName: {
            color: '#000',
            fontFamily: 'Shrikhand, sans-serif',
            fontSize: '20px',
            marginLeft: '10px',
        },
        buttonsContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'right',
            justifyContent: 'flex-end',
        },
        button: {
            marginRight: '10px'
        },
        navbarButton: {
            borderRadius: '6px', 
            minWidth: '80px', 
            height: '30px', 
            marginTop: '3px'
        }
    };

    const navTabs = [
        {
            "buttonName": 'Network',
            "listItems": [
                {
                    "title": "Item 1",
                    "link": ""
                },
                {
                    "title": "Item 2",
                    "link": ""
                },
                {
                    "title": "Item 3",
                    "link": ""
                },
            ]
        },
        {
            "buttonName": 'Companies',
            "listItems": [
                {
                    "title": "Item 1",
                    "link": ""
                },
                {
                    "title": "Item 2",
                    "link": ""
                },
                {
                    "title": "Item 3",
                    "link": ""
                },
            ]
        },
        {
            "buttonName": 'Jobs',
            "listItems": [
                {
                    "title": "Item 1",
                    "link": ""
                },
                {
                    "title": "Item 2",
                    "link": ""
                },
                {
                    "title": "Item 3",
                    "link": ""
                },
            ]
        }
    ]

    const openAlertModal = () => {
        setShowAlertModal(true);
    };

    const closeAlertModal = () => {
        setShowAlertModal(false);
    };

    return (
        <Paper id="navbar" sx={{position: 'fixed', top: 0, width: '100%', zIndex: 1000 , backgroundColor: navbarOpacity === 0 ? 'transparent' : '', boxShadow: 'none'}}>
            <Box sx={styles.mainContainer}>
                <Grid container spacing={2}>
                    <Grid item xs={5} sm={7} md={7} lg={7} xl={7}>
                        <Grid container spacing={2}>
                            <Grid item sm={4} md={4} lg={4} xl={4} sx={styles.imageContainer}>
                                <img src="ConectYu_logo.png" style={styles.logo} alt="conectyu_logo"/>
                                <Typography varaint="body1" sx={styles.brandName}>ConectYu</Typography>
                            </Grid>
                            <Grid item sm={8} md={8} lg={8} xl={8} sx={styles.buttonsContainer}>
                                {/* {
                                    navTabs.map((item) => {
                                        return (
                                            <NavMenuButton 
                                                buttonName={item.buttonName}
                                                listItems={item.listItems}
                                                style={styles.button}
                                            />
                                        )
                                    })
                                } */}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={7} sm={5} md={5} lg={5} xl={5} sx={styles.buttonsContainer}>
                        <CustomButton 
                            varaint="filled"
                            bgColor="#58758C"
                            textColor="white"
                            style={{...styles.navbarButton, marginRight: '15px' }}
                            onHoverColor="white"
                            onHoverbgColor="#1E4276"
                            onClick={openAlertModal}
                        >Login</CustomButton>
                        <CustomButton 
                            varaint="filled"
                            bgColor="#1E4276"
                            textColor="white"
                            style={styles.navbarButton}
                            onHoverColor="white"
                            onHoverbgColor="#58758C"
                            onClick={openAlertModal}
                        >Sign up</CustomButton>
                    </Grid>
                </Grid>

                {
                    showAlertModal 
                        ? (
                            <CustomModal 
                                title="Hang tight!" 
                                desc="We are working on this feature to make it available!"
                                style={{
                                    backgroundColor: 'white', 
                                    width: '80%', 
                                    marginLeft: '5%', 
                                    marginTop: '30%',
                                    padding: '25px',
                                    borderRadius: '25px'
                                }} 
                                setOnClose={closeAlertModal} 
                                imageUri="./assets/hangon.gif"
                            />
                        )
                    : null
                }
            </Box>
        </Paper>
    )
};

export default Navbar;
import { Box, Grid } from "@mui/material";

import PathIconDetails from "./pathIconDetails";

const PathObject = () => {
    const styles = {
        mainContainer: {
            marginTop: '25px'
        }
    }

    return (
        <Box sx={styles.mainContainer}>
            <Grid container>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <PathIconDetails 
                        icon="./assets/userPathIcon1.svg" 
                        title="Register Your Account"
                        description="Join as a student, Individual or an Organization."
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <PathIconDetails 
                        icon="./assets/userPathIcon2.svg" 
                        title="Add Your Achievements"
                        description="Add all of your achievements and enhance your profile."
                    />
                </Grid>
            </Grid>
            <Grid container sx={{marginTop: '30px'}}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <PathIconDetails 
                        icon="./assets/userPathIcon3.svg" 
                        title="Earn Credits"
                        description="Make all your achievements verified and earn credits for them."
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <PathIconDetails 
                        icon="./assets/userPathIcon4.svg" 
                        title="Stand out to companies"
                        description="Get a chance to get noticed by top companies."
                    />
                </Grid>
            </Grid>
        </Box>
    )
};

export default PathObject;
import { useState } from "react";
import { Box, Modal, Typography } from "@mui/material"

const CustomModal = ({title, desc, style, setOnClose, imageUri}) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        setOnClose();
    };

    const styles = {
        lottieimage: {
            width: '80%',
            height: '250px',
            marginLeft: '10%',
            marginTop: '50px',
            borderRadius: '15px'
        },
        titleText: {
            fontSize: '16px',
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 'bold'
        },
        descText: {
            fontSize: '14px',
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center'
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <img src={imageUri} style={styles.lottieimage} alt="modalPic" />
                <Typography sx={styles.titleText}>
                    {title}
                </Typography>
                <Typography sx={styles.descText}>
                    {desc}
                </Typography>
            </Box>
        </Modal>
    )
};

export default CustomModal
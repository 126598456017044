import { Box, Grid, Typography } from "@mui/material"

const CollabSkillComponent = ({item}) => {
    const styles = {
        mainContainer: {
            border: '2px solid #19A7CE',
            borderRadius: '30px',
            padding: '5px',
            cursor: 'pointer',
            "&:hover": {
                backgroundColor: '#19A7CE',
                "& .titleText": { 
                    color: 'white'
                }
            }
        },
        titleText: {
            color: '#19A7CE',
            textAlign: 'center',
            fontSize: '12px'
        }
    }

    return (
        <Box sx={styles.mainContainer}>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography className="titleText" sx={styles.titleText}>{item}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
};

export default CollabSkillComponent;
import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
// import EastIcon from '@mui/icons-material/East';

import CustomButton from "./common/customButton";
import CustomModal from "./common/customModal";

const BannerComponent = () => {
    const [showAlertModal, setShowAlertModal] = useState(false);

    const styles = {
        mainContainer: {
            minHeight: '500px',
            overflow: 'hidden'
        },
        leftContainer: {
            backgroundColor: '#EDEDED'
        },
        rightContainer: {
            backgroundColor: '#19A7CE'
        },
        mainTitle: {
            color: 'var(--Text-Gray-900, #18191F)',
            fontFamily: "Noticia Text",
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '25px',
            letterSpacing: '0.2px',    
            width: '80%'        
        },
        contentContainer:{
            marginLeft: '5%',
            marginTop: '60%',
            flexDirection: 'column'
        },
        descText: {
            fontSize: '10px',
            marginTop: '2.5%',
            textAlign: 'justify',
            zIndex: 1,
            width: '80%' 
        },
        button: {
            borderRadius: '6px',
            marginRight: '75px', 
            width: '120px',
            height: '30px',
            marginTop: '10%'
        },
        containerImage: {
            width: '51px',
            height: '51px',
            borderRadius: '75px'
        },
        containerLargerImage: {
            width: '66px',
            height: '66px',
            borderRadius: '33px'
        },
        containerLargestImage: {
            width: '76px',
            height: '76px',
            flexShrink: 0,
            borderRadius: '95px',
            overflow: 'hidden'
        },
        dottedSquare: {
            width: '50px',
            height: '50px'
        }
    }

    const openAlertModal = () => {
        setShowAlertModal(true);
    };

    const closeAlertModal = () => {
        setShowAlertModal(false);
    };

    return (
        <Box>
            <Grid container sx={styles.mainContainer}>
                <Grid item xs={7} sm={7} md={7} lg={7} xl={7} sx={styles.leftContainer}>
                    <Grid container sx={styles.contentContainer}>
                        <Typography variant="body1" sx={styles.mainTitle}>Empowering careers, forging connections</Typography>
                        <Typography variant="body1" sx={styles.descText}>Embark on the Next Era of Professional Networking with ConectYu. Advance Your Journey Today!</Typography>
                        <CustomButton 
                            varaint="filled"
                            bgColor="#1E4276"
                            textColor="white"
                            style={styles.button}
                            onHoverColor="white"
                            onHoverbgColor="#58758C"
                            onClick={openAlertModal}
                        >Get Started</CustomButton>
                    </Grid>
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5} xl={5} sx={{ ...styles.rightContainer, position: 'relative' }}>
                    <img alt="pic" src="./assets/halfCircletHorizontal.svg" style={{ width: '51px', height: '25px', flexShrink: 0,  position: 'absolute', top: '12.5%', left: '-6%' }} />
                    <img alt="pic" src="./assets/suitpic.jpg" style={{ ...styles.containerImage, position: 'absolute', top: '27.5%', left: '-25px' }}/>
                    <div style={{ ...styles.containerLargestImage, position: 'absolute', top: '19%', left: '41%', zIndex: 1 }} ><img alt="pic" src="./assets/graduateGirlpic.jpg" width='75px' height='100px' /></div>
                    <img alt="pic" src="./assets/dottedSquare.svg" style={{ ...styles.dottedSquare, position: 'absolute', top: '17.5%', left: '67.5%', zIndex: 0 }} />
                    <img alt="pic" src="./assets/halfCircle.svg" style={{ width: '34px', height: '34px', position: 'absolute', top: '45%', right: 0, transform: 'rotate(-90deg)' }} />
                    <img alt="pic" src="./assets/keyboardpic.jpg" style={{ ...styles.containerImage, position: 'absolute', top: '58%', left: '16%', zIndex: 1 }} />
                    <img alt="pic" src="./assets/dashedCircle.svg" style={{ ...styles.containerImage, position: 'absolute', top: '60.5%', left: '26%', zIndex: 0}} />
                    <img alt="pic" src="./assets/grouppic.jpg" style={{ ...styles.containerLargerImage, position: 'absolute', top: '72.5%', left: '50%', zIndex: 1 }} />
                    <img alt="pic" src="./assets/dottedSquare.svg" style={{ ...styles.dottedSquare, position: 'absolute', top: '71.5%', left: '78%', zIndex: 0 }} />
                    <img alt="pic" src="./assets/halfCircle.svg" style={{ width: '34px', height: '34px', flexShrink: 0, position: 'absolute', bottom: 0, left: '50%', transform: 'translateX(-50%)' }} />
                </Grid>
            </Grid>

            {
                showAlertModal 
                ? (
                    <CustomModal 
                        title="Hang tight!" 
                        desc="We are working on this feature to make it available!"
                        style={{
                            backgroundColor: 'white', 
                            width: '80%', 
                            marginLeft: '5%', 
                            marginTop: '30%',
                            padding: '25px',
                            borderRadius: '25px'
                        }} 
                        setOnClose={closeAlertModal} 
                        imageUri="./assets/hangon.gif"
                    />
                )
                : null
            }
        </Box>
    )
};

export default BannerComponent;
import { useState, useEffect } from 'react';
import Routing from './Routes';
import NoInternet from './components/NoInternet';

function App() {
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    const checkInternetConnection = async () => {
      try {
        // await fetch('https://www.conectyu.com');
        setIsConnected(true);
      } catch (error) {
        setIsConnected(false);
      }
    };
    checkInternetConnection();
    const interval = setInterval(checkInternetConnection, 5000);
    return () => clearInterval(interval);
  }, []);

  // if (!isConnected) {
  //   return <NoInternet />
  // }

  return (
    <div className='App'>
      <Routing />
    </div>
  );
}

export default App;

import { Box, Grid, Typography } from "@mui/material";

const CollabRightComponent = () => {
    const styles = {
        mainContainer: {
            border: '3px solid #19A7CE',
            padding: '35px',
            borderRadius: '30px',
            borderBottomWidth: '90%',
        },
        titleText: {
            fontSize: '14px',
            fontWeight: 'bold',
        },
        descText: {
            marginTop: '15px',
            fontSize: '12px',
            textAlign: 'justify'
        }
    }
    return (
        <Box>
            <Grid container sx={styles.mainContainer}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography sx={styles.titleText}>Discover Your Perfect Career or Internship Fit</Typography>
                    <Typography sx={styles.descText}>Chart your professional path to uncover the ideal job or internship match.</Typography>
                </Grid>
            </Grid>
        </Box>
    )
};

export default CollabRightComponent;
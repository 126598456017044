import { Box, Grid, Typography } from "@mui/material"

import ListLink from "./listLink";

const FooterLinksComponent = () => {
    const styles = {
        listerheader: {
            fontSize: '13px',
            fontWeight: 'bold',
            color: 'white',
            marginBottom: '10px'
        },
        storeimage: {
            width: '80px',
            marginTop: '10px',
            cursor: 'pointer'
        }
    }
    const companyList = [
        {
            "title": "About us",
            "url": "est"
        },
        {
            "title": "Blog",
            "url": ""
        },
        {
            "title": "Careers",
            "url": ""
        },
        {
            "title": "Contact us",
            "url": ""
        },
    ];

    const supportList = [
        {
            "title": "Help Center",
            "url": ""
        },
        {
            "title": "Safety Center",
            "url": ""
        },
        {
            "title": "Community Guidelines",
            "url": ""
        }
    ];

    const legalList = [
        {
            "title": "Terms & Conditions",
            "url": ""
        },
        {
            "title": "Cookie Policy",
            "url": ""
        },
        {
            "title": "Privacy Policy",
            "url": ""
        }
    ];

    return (
        <Box>
            <Grid container>
                <Grid item xs={0} sm={1} md={1} lg={1} xl={1}></Grid>
                <Grid item xs={3} sm={1} md={1} lg={1} xl={1}>
                    <Typography sx={styles.listerheader}>Company</Typography>
                    {
                        companyList.map((item, index) => {
                            return (
                                <ListLink key={index} title={item.title} uri={item.url} />
                            )
                        })
                    }
                </Grid>
                <Grid item xs={0} sm={1} md={1} lg={1} xl={1}></Grid>
                <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                    <Typography sx={styles.listerheader}>Support</Typography>
                    {
                        supportList.map((item, index) => {
                            return (
                                <ListLink key={index} title={item.title} uri={item.url} />
                            )
                        })
                    }
                </Grid>
                <Grid item xs={0} sm={1} md={1} lg={1} xl={1}></Grid>
                <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                    <Typography sx={styles.listerheader}>Legal</Typography>
                    {
                        legalList.map((item, index) => {
                            return (
                                <ListLink key={index} title={item.title} uri={item.url} />
                            )
                        })
                    }
                </Grid>
                <Grid item xs={0} sm={1} md={1} lg={1} xl={1}></Grid>
                <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                    <Typography sx={styles.listerheader}>Install Apps</Typography>
                    <img src="./assets/playstorepic.svg" style={styles.storeimage} /><br />
                    <img src="./assets/appstorepic.svg" style={styles.storeimage}/>
                </Grid>
                <Grid item xs={0} sm={1} md={1} lg={1} xl={1}></Grid>
            </Grid>
        </Box>
    )
};

export default FooterLinksComponent;
import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";

import CustomButton from "./common/customButton";
import CustomModal from "./common/customModal";

const LabelBanner = () => {
    const [showAlertModal, setShowAlertModal] = useState(false);

    const styles = {
        mainContainer: {
            backgroundColor:  '#19A7CE',
            padding: '25px'
        },
        titleText: {
            fontSize: '24px',
            fontWeight: 'bold',
            color: 'white',
            width: '90%',
            marginLeft: '5%',
            textAlign: 'center'
        },
        button: {
            borderRadius: '6px', 
            minWidth: '50%', 
            marginTop: '25px', 
            marginLeft: '25%', 
            height: '50px'
        }
    }
    
    const openAlertModal = () => {
        setShowAlertModal(true);
    };

    const closeAlertModal = () => {
        setShowAlertModal(false);
    };

    return (
        <Box sx={styles.mainContainer}>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography sx={styles.titleText}>Accelerate Your Career Journey with ConectYu</Typography>
                    <CustomButton
                        varaint="filled"
                        bgColor="#1E4276"
                        textColor="white"
                        style={styles.button}
                        onHoverColor="white"
                        onHoverbgColor="#58758C"
                        onClick={openAlertModal}
                    >Get Started</CustomButton>
                </Grid>
            </Grid>
            {
                showAlertModal 
                    ? (
                        <CustomModal 
                            title="Hang tight!" 
                            desc="We are working on this feature to make it available!"
                            style={{
                                backgroundColor: 'white', 
                                width: '80%', 
                                marginLeft: '5%', 
                                marginTop: '10%',
                                padding: '25px',
                                borderRadius: '25px'
                            }} 
                            setOnClose={closeAlertModal} 
                            imageUri="./assets/hangon.gif"
                        />
                    )
                : null
            }
        </Box>
    )
};

export default LabelBanner;
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material"

const ListLink = ({title, uri}) => {
    const navigate = useNavigate();

    const redirectTo = () => {
        navigate('/'+uri);
    };

    const styles = {
        mainContainer: {
            cursor: 'pointer'
        },
        titleText: {
            color: 'white',
            marginTop: '10px',
            fontSize: '11px',
            "&:hover": {
                color: '#58758C',
                textDecoration: 'underline',
                fontSize: '14px',
            }
        }
    }

    return (
        <Box onClick={redirectTo} sx={styles.mainContainer}>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography sx={styles.titleText}>{title}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
};

export default ListLink;
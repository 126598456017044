import { Box, Grid, Typography } from "@mui/material"

const CollabBottomComponent = () => {
    const styles = {
        mainContainer: {
            border: '3px solid #19A7CE',
            padding: '15px',
            borderRadius: '30px',
            marginTop: '25px',
        },
        titleText: {
            fontSize: '14px',
            fontWeight: 'bold'
        },
        descText: {
            marginTop: '15px',
            fontSize: '12px',
            textAlign: 'justify'
        },
        image: {
            width: '100%',
            height: '100px',
            borderTopLeftRadius: '30px',
            borderBottomLeftRadius: '30px'
        }
    }
    return (
        <Box>
            <Grid container sx={styles.mainContainer}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <img src="./assets/collabpic.jpeg" style={styles.image}/>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <Typography sx={styles.titleText}>Uncover the Depths of Community</Typography>
                            <Typography sx={styles.descText}>ConectYu - Your Pathway to Limitless Career Prospects. Enhance, Engage, and Excel!</Typography>
                        </Grid> 
                    </Grid>    
                </Grid> 
            </Grid>
        </Box>
    )
};

export default CollabBottomComponent;
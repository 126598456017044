import { Route, Routes } from "react-router-dom";

import LandingPage from "./pages/landingPage";

const Routing = () => {
    return (
        <Routes>
            <Route exact path="/" element={<LandingPage />} />
        </Routes>
    )
}

export default Routing;
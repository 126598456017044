import { Box, Grid } from "@mui/material";

import CollabTopComponent from "./collabTopComponent";
import CollabRightComponent from "./CollabRightComponent";
import CollabBottomComponent from "./collabBottomComponent";

const CollabComponent = () => {
    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={0} sm={1} md={1} lg={1} xl={1}></Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <CollabTopComponent />
                    <CollabBottomComponent />
                </Grid>
                <Grid item xs={0} sm={1} md={1} lg={1} xl={1}></Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <CollabRightComponent />
                </Grid>
                <Grid item xs={0} sm={1} md={1} lg={1} xl={1}></Grid>
            </Grid>
        </Box>
    )
};

export default CollabComponent;
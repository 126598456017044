import { Box } from "@mui/material";

import Navbar from "../components/navbar";
import BannerComponent from "../components/BannerComponent";
import UserPath from "../components/userPath";
import ExploreComponent from "../components/exploreComponent";
import LabelBanner from "../components/labelBanner";
import NoticeComponent from "../components/noticeComponent";
import ServicesComponent from "../components/servicesComponent";
import Footer from "../components/footer";

const LandingPage = () => {

    return (
        <Box>
            <Navbar />
            <BannerComponent />
            <UserPath />
            <ExploreComponent />
            <LabelBanner />
            <NoticeComponent />
            <ServicesComponent />
            <Footer />
        </Box>
    )
};

export default LandingPage;
import { Box, Grid, Typography } from "@mui/material"

const NoticeComponent = () => {
    const styles = {
        mainContainer: {
            backgroundColor:  '#EDEDED',
            padding: '5px'
        },
        titleText: {
            fontSize: '14px',
            fontWeight: 'bold',
            width: '90%',
            marginLeft: '10%',
            marginTop: '10%'
        },
        descText: {
            // textAlign: 'justify',
            width: '90%',
            marginLeft: '10%',
            marginTop: '15px',
            fontSize: '12px'
        },
        image: {
            width: '110%',
            height: '80%',
            marginTop: '30px',
            marginLeft: '-10px'
        }
    }
    return (
        <Box sx={styles.mainContainer}>
            <Grid container spacing={3}>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                    <Typography sx={styles.titleText}>Elevate Your Professional Presence and Garner Attention</Typography>
                    <Typography sx={styles.descText}>
                        Discover in ConectYu the ultimate platform to curate a dynamic professional profile, spotlighting your 
                        skills, experiences, and successes. Distinguish yourself from the crowd and captivate leading companies 
                        in search of talent like yours.
                    </Typography>
                    <Typography sx={styles.descText}>
                        Seize command of your career trajectory now and unlock boundless avenues for progress and achievement.
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <img src="./assets/laptopgirl.png" style={styles.image}/>
                </Grid>
            </Grid>
        </Box>
    )
}

export default NoticeComponent;
import { Box, Grid, Typography } from "@mui/material";
import EastIcon from '@mui/icons-material/East';

import CollabSkillComponent from "./collabSkillComponent";
import CustomButton from "./common/customButton";

const CollabTopComponent = () => {
    const styles = {
        mainContainer: {
            border: '3px solid #19A7CE',
            padding: '20px',
            borderRadius: '30px',
            borderBottomWidth: '90%'
        },
        titleText: {
            fontSize: '14px',
            fontWeight: 'bold'
        },
        descText: {
            marginTop: '10px',
            fontSize: '12px',
            textAlign: 'justify'
        },
        collabSkillsContainer: {
            marginTop: '10px'
        }
    }

    const collabSkills = [
        "Entrepreneurship",
        "Technology",
        "Investments",
        "Education",
        "Business",
        "Marketing"
    ]

    return (
        <Box>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={styles.mainContainer}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography sx={styles.titleText}>Fostering Collaborative Skills: Redefining Community Knowledge Access</Typography>
                                <Typography sx={styles.descText}>We are revolutionizing the way community knowledge is unlocked by 
                                    enabling experts to contribute insights directly to their respective pages or profiles.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container spacing={2} sx={styles.collabSkillsContainer}>
                                    {
                                        collabSkills.map((item, index) => {
                                            if (index < 5){
                                                return (
                                                    <Grid key={index} item sm={4} md={4} lg={4} xl={4}>
                                                        <CollabSkillComponent key={index} item={item} />
                                                    </Grid>
                                                )
                                            }
                                        })
                                    }
                                    <Grid item sm={4} md={4} lg={4} xl={4}>
                                        <CustomButton
                                            varaint="filled"
                                            bgColor="#1E4276"
                                            textColor="white"
                                            style={{
                                                borderRadius: '30px', 
                                                height: '30px',
                                                width: '100%',
                                                marginTop: '2px',
                                                fontSize: '12px'
                                            }}
                                            onHoverColor="white"
                                            onHoverbgColor="#58758C"
                                            onClick={() => {}}
                                        >See All &nbsp; <EastIcon sx={{fontSize: '12px'}} /></CustomButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
};

export default CollabTopComponent;
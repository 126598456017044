import { useState } from "react";
import { Button } from "@mui/material";

const CustomButton = ({varaint, bgColor, textColor, style, onHoverbgColor, onHoverColor, children, onClick}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const onClickHandler = () => {
        onClick();
    };

    const styles = {
        button: {
            textTransform: 'none',
            backgroundColor: isHovered ? onHoverbgColor : bgColor || '#1E4276',
            color: isHovered ? onHoverColor : textColor || 'white',
            ...style
        }
    }

    return (
        <Button
            variant={varaint}
            style={styles.button}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onClickHandler}
        >
            {children}
        </Button>
    )
};

export default CustomButton;
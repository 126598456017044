import { Box, Grid, Typography } from "@mui/material";

import ServiceCard from "./serviceCard";

const ServicesComponent = () => {
    const styles = {
        mainContainer: {
            backgroundColor:  '#EDEDED',
            padding: '25px'
        },
        titleText: {
            fontSize: '24px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '50px',
            fontFamily: 'Roboto'
        },
        hightLightText: {
            color: '#19A7CE'
        }
    }

    const data = [
        {
            "title": "Credit Based Search",
            "description": "Identify Top Talent Based on Verified Achievements credits.",
            "image": "./assets/servicecard1.jpg"
        },
        {
            "title": "Work Opporutnities",
            "description": "Explore a Diverse Array of Job and Internship Opportunities Offered by Numerous Companies.",
            "image": "./assets/servicecard2.jpg"
        },
        {
            "title": "Companies",
            "description": "Uncover Companies Within Our Network.",
            "image": "./assets/servicecard3.jpg"
        },
        {
            "title": "Educational Institutions",
            "description": "Companies Can Find the Ideal Recruitment Hub to Connect with Dedicated Talent.",
            "image": "./assets/servicecard4.jpg"
        }
    ]

    return (
        <Box sx={styles.mainContainer}>
            <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography sx={styles.titleText}>Explore <span style={styles.hightLightText}>with</span> us</Typography>
            </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={3}>
                        {
                            data.map((item, index) => {
                                return (
                                    <Grid item xs={6} sm={3} md={3} lg={3} xl={3} key={index}>
                                        <ServiceCard title={item.title} description={item.description} imageUri={item.image} />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
};

export default ServicesComponent;